<template>
  <div>
    <b-modal v-model="modal.coupons" :title=" $t('dashboard.discounts.types.newCoupons') " content-class="card form-rounded-inputs card-ecart"
      @hidden="resetDiscount()" header-class="card-header" cancel-variant="none" size="xl" ok-variant="success"
      :ok-title="$t('general.button.create')" centered>

      <v-observer tag="form" class=" animated fadeIn" ref="formDiscountCoupon" @submit.prevent="fnValidateDiscount">

        <div class="row">
          <div class="col-12 col-lg-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.store') ">
              <div class="form-group ">
                <label>
                  <span v-text="$t('general.form.store')"></span><br>
                  <small v-text="$t('informational.form.shippingDiscount.store')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.storeId">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="store.id" v-for=" (store,index) in storesList " :key="index"
                      v-text=" store.name ? store.name : fnStoreURL(store.url) ">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fa " :class=" loading.stores ? 'fa-spinner fa-spin':'fa-store' "></i>
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6 ">
            <v-validation rules="required|min:3|max:50" v-slot="{ errors }" :name=" $t('general.form.name') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.name')"></span><br>
                  <small v-text="$t('informational.form.coupon.name')"></small>
                </label>
                <div class="input-group">
                  <input type="text" class="form-control" v-model="newDiscount.name" placeholder="Spring break"
                    :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-keyboard "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>
        </div>
        <hr class="mt-1">

        <div class="row">
          <div class="col-12 col-lg-6 ">
            <v-validation :rules=" { regex: /^[a-z0-9]+$/i , required: true , min:5 , max:10 } " v-slot="{ errors }"
              :name=" $t('general.form.coupon') ">
              <div class="form-group">
                <div class="row">
                  <div class="col col-xl">
                    <label>
                      <span v-text="$t('general.form.coupon')"></span>
                      <small v-text=" ' (' + $t('general.form.alphanumeric') + ')' "></small> <br>
                      <small v-text="$t('informational.form.coupon.coupon')"></small>
                    </label>
                  </div>
                  <div class="col-auto col-lg-auto ml-auto">
                    <button class="btn btn-xs btn-outline-info" type="button" @click="fnGenerateCoupon()" v-text="$t('general.button.generate')" > </button>
                  </div>
                </div>

                <div class="input-group">
                  <input type="text" class="form-control" v-model="newDiscount.coupon" placeholder="e10cart"
                    :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-gift "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-group">
              <label class="w-100">
                <div class="row">
                  <div class="col">
                    <span v-text=" $t('general.form.limitOfUse') "> </span>
                  </div>
                  <div class="col-auto ">
                    <div class="form-check ">
                      <input type="checkbox" class="form-check-input" id="isUnlimited"
                        v-model="newDiscount.isUnlimited">
                      <label class="form-check-label" for="isUnlimited" v-text="$t('general.form.unlimited')"> </label>
                    </div>
                  </div>
                </div>
                <small v-text="$t('informational.form.coupon.limit')"></small>
              </label>
              <v-validation rules="required|min_value:1|max_value:500" v-slot="{ errors }"
                :name=" $t('general.form.limitOfUse') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.available" placeholder="100"
                    :class=" { 'is-invalid':(errors.length > 0 ) } " :disabled="newDiscount.isUnlimited">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa " :class=" newDiscount.isUnlimited ? 'fa-infinity':'fa-hashtag' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>
        </div>
        <hr class="mt-1">
        <div class="row align-items- justify-content-end ">

          <div class="col-12 col-lg-6 ">
            <div class="form-group ">
              <label class="w-100">
                <div class="row">
                  <div class="col">
                    <span v-text=" $t('general.form.discount') "> </span>
                  </div>
                  <div class="col-auto ">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isPrecentage" v-model="newDiscount.isPercent"
                        @change=" newDiscount.amount = null, newDiscount.minPurchase = null ">
                      <label class="form-check-label" for="isPrecentage" v-text="$t('general.form.inPercentage')">
                      </label>
                    </div>
                  </div>
                </div>
                <small v-text="$t('informational.form.coupon.amount')"></small>
              </label>
              <v-validation :rules=" newDiscount.isPercent ? 'required|min_value:5': 'required|min_value:1' "
                v-slot="{ errors }" :name=" $t('general.form.discount') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.amount"
                    :placeholder="newDiscount.isPercent ? '100%':'10$' " :max="newDiscount.isPercent ? 100 : '' "
                    :class=" { 'is-invalid': (errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa" :class=" newDiscount.isPercent ? 'fa-percentage':'fa-dollar-sign' "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-lg-6 ">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.currency') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.currency')"></span><br>
                  <small v-text="$t('informational.form.coupon.currency')"></small>
                </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                    v-model="newDiscount.currency">
                    <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                    <option :value="currency" v-for=" (currency,index) in CONST.CURRENCIES " :key="index"
                      v-text=" currency "> </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fa fa-money-bill"></i>
                    </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6">
            <v-validation
              :rules=" !newDiscount.isPercent ? ('min_value:' + (Number(newDiscount.amount) * 2 )): 'min_value:5' "
              v-slot="{ errors }" :name=" $t('general.form.minPurchase') ">
              <div class="form-group">
                <label>
                  <span v-text="$t('general.form.minPurchase')"></span><br>
                  <small v-text="$t('informational.form.coupon.minPurchase')"></small>
                </label>
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.minPurchase" placeholder="50"
                    :class=" { 'is-invalid':(errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-dollar-sign "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-lg-6 animated fadeIn" v-show="newDiscount.isPercent">
            <div class="form-group ">
              <label>
                <span v-text="$t('general.form.maxDiscount')"></span><br>
                <small v-text="$t('informational.form.coupon.maxPurchase')"></small>
              </label>
              <v-validation :rules=" newDiscount.isPercent ? ('min_value:'+(newDiscount.minPurchase * 0.1)) : '' "
                v-slot="{ errors }" :name=" $t('general.form.maxPurchase') ">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="newDiscount.maxPurchase"
                    :class=" { 'is-invalid': (errors.length > 0 ) } ">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right">
                      <i class="fa fa-dollar-sign "></i>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0 ">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>
          <div class="col-12">
            <hr class="mt-1">
            <p class="m-0"><strong class="f-w-500" v-text="$t('general.form.availabilityDate')"> </strong></p>
            <div v-if="newDiscount.applyDates">
              <p>
                <small>
                  <span v-text="$t('informational.form.coupon.availabilityDate') + ' '"></span>
                  <a class="text-info cursor-pointer" @click="newDiscount.applyDates = !newDiscount.applyDates"
                    v-text=" $t('general.button.hideForm') "></a>.
                </small>
              </p>
              <hr>
              <FormaDates @set-dates=" (dates)=>{ newDiscount.dates = dates } " :initialDate="newDiscount.dates.initial"
                :finishDate="newDiscount.dates.finish" />
            </div>
            <p v-else>
              <small>
                <span v-html="$t('informational.form.coupon.availabilityDateMessage') + ' '"></span>
                <a @click="newDiscount.applyDates = !newDiscount.applyDates"
                  class="text-lowercase text-info cursor-pointer" v-text=" $t('general.button.here') "></a>.
              </small>
            </p>
          </div>
        </div>
        <input type="submit" hidden>
      </v-observer>

      <template v-slot:modal-footer>
        <p class="text-right">
          <button class="btn" v-text="$t('general.button.close')" @click="changeModal({ coupons: false })"></button>
          <button class="btn btn-success" :disabled="loading.newDiscount" @click="fnValidateDiscount()">
            <span v-text=" newDiscount.id ? $t('general.button.update') : $t('general.button.create') "
              v-if=" !loading.newDiscount "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations,
    mapGetters
  } from 'vuex';
  import FormaDates from "@/components/FormDates.vue";
  export default {
    name: 'DiscountsCoupons',
    components: {
      FormaDates
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('discounts', ['modal', 'loading', 'newDiscount', 'storesList']),
    },
    methods: {
      ...mapActions('discounts', ['fnApiCreateDiscountCoupon']),
      ...mapMutations('discounts', ['resetDiscount', 'changeModal']),
      async fnValidateDiscount() {
        if (await this.$refs['formDiscountCoupon'].validate()) {
          this.fnApiCreateDiscountCoupon(this.newDiscount);
        } else {}
      },
      fnGenerateCoupon() {
        let tempRandom = Math.random().toFixed(2).toString().slice(2);
        let tempCoupon = 'ecart' + tempRandom;

        tempRandom = Math.random().toFixed(3).toString().slice(2);
        for (let char of tempRandom) {
          tempCoupon += String.fromCharCode(97 + Number(char));
        }
        this.newDiscount.coupon = tempCoupon ;
        // console.log(Math.random());
      }
    },
    mounted() {},
  }
</script>